import React from 'react'

const HeroOne = () => {
    return (
        <div className='relative flex flex-row items-center'>
            <img class="w-full" alt="hero" src={require('../../Assets/Dog.png')} />
            <div className='absolute top-[20%] flex w-full justify-center'>
                <img alt='hero' className='shake h-[250px] sm:h-[300px] md:h-[600px]' src={require('../../Assets/logo_ppp.png')} />
            </div>
        </div>
    )
}

export default HeroOne