import React from 'react'

const Hero = () => {
    return (
        <div>
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font text-[40px] md:text-[70px] mb-4 font-medium text-[#9e6c00]">Narrative
                            <br class="hidden lg:inline-block" />
                        </h1>
                        <p class="mb-8 text-black text-[20px] md:text-[30px] leading-relaxed">
                            While everyones been chasing mooners Neiro popped out of nowhere, another beloved dog in the crypto space. While the community was bundling strength the cabal came out of nowhere and sucker punched the community, yet the community didn’t give up.. the community stood up and KO’d the cabal, let the PVP start🔔🥊
                        </p>
                        <div class="flex justify-center gap-4">
                            <a href='https://x.com/neirotakeover'>
                            <button class="bg-black text-white p-4 text-[30px] rounded-md">
                                Twitter
                            </button>
                            </a>
                            <a href='https://t.me/neirotakeover'>
                            <button class="bg-black text-white p-4 text-[30px] rounded-md">
                                Telegram
                            </button>
                            </a>
                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src={require('../../Assets/dog4.png')} />
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Hero