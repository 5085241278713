import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[#ffdaa4] w-[100vw] border-y-4 border-black'>
            <Marquee>
                <div className='h-[80px] md:h-[140px] flex justify-center items-center gap-4'>
                    <div className='flex items-center gap-4'>
                        <img src={require('../../Assets/ppp1.png')} className='h-[70px] md:h-[100px]' />
                        <img src={require('../../Assets/ppp2.png')}  className='h-[70px] md:h-[100px]'/>
                        <img src={require('../../Assets/ppp3.png')} className='h-[70px] md:h-[100px]' />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img src={require('../../Assets/ppp1.png')} className='h-[70px] md:h-[100px]' />
                        <img src={require('../../Assets/ppp2.png')}  className='h-[70px] md:h-[100px]'/>
                        <img src={require('../../Assets/ppp3.png')} className='h-[70px] md:h-[100px]' />
                    </div>
                    <div className='flex items-center gap-4'>
                        <img src={require('../../Assets/ppp1.png')} className='h-[70px] md:h-[100px]' />
                        <img src={require('../../Assets/ppp2.png')}  className='h-[70px] md:h-[100px]'/>
                        <img src={require('../../Assets/ppp3.png')} className='h-[70px] md:h-[100px]' />
                    </div>
                    
                </div>
            </Marquee>
        </div>
    )
}

export default Slider