import React from 'react'

const Tokenomics = () => {
    return (
        <div><section class="text-gray-600 body-font">
            <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                <img class="w-full sm:w-[80%] md:w-[50%]" alt="hero" src={require('../../Assets/dog21.png')} />
                <div class="text-center lg:w-2/3 w-full">
                    <h1 class="title-font text-[50px] md:text-[90px] mb-4 font-medium text-[#9e6c00]">Tokenomics</h1>
                    <p class="mb-8 leading-relaxed text-[#000000] text-[30px] md:text-[40px]">0% Tax</p>
                    <p class="mb-8 leading-relaxed text-[#130000] text-[30px] md:text-[40px]">1 BIllion $NTO</p>
                    <p class="mb-8 leading-relaxed text-[#130000] text-[30px] md:text-[40px]">LP Burned</p>
                    <div class="flex justify-center">
                        <a href='https://t.me/neirotakeover'>
                            <button class="bg-black text-white p-4 text-[30px] rounded-md">
                                Buy Now
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </section></div>
    )
}

export default Tokenomics