import logo from './logo.svg';
import './App.css';
import Hero from './components/Hero/Hero';
import HeroOne from './components/Hero/HeroOne';
import Slider from './components/Slider/Slider';
import Tokenomics from './components/Tokenomics/Tokenomicsx';

function App() {
  return (
    <div className='max-w-dvw overflow-clip bg-main'>
      <HeroOne/>
      <Slider/>
      <Hero/>
      <Slider/>
      <Tokenomics/>
      <Slider/>
    </div>
  );
}

export default App;
